document.addEventListener('DOMContentLoaded', () => {
  const observer = new MutationObserver((mutationsList) => {
    mutationsList.forEach(() => {
      const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
      if (popoverTriggerList.length) {
        popoverTriggerList.forEach((popoverTriggerEl) => bootstrap.Popover.getOrCreateInstance(popoverTriggerEl));
      }
    });
  });
  observer.observe(window.document.body, { childList: true, subtree: true });
});
